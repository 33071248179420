<template>
  <v-row>
    <v-card id="account-setting-card">
      <StudentInfosForm
        :student-infos="student"
        :formations="formations"
        :tuteurs="tuteurs"
        :partenaires="partenaires"
      ></StudentInfosForm>
    </v-card>
  </v-row>
</template>

<script>

import StudentInfosForm from './StudentInfosForm.vue'

export default {
  components: {
    StudentInfosForm,
  },
  data() {
    return {
      partenaires: [],
      tuteurs: [],
      student: {
        adresse: {
          ligne1: '',
          ligne2: '',
          postcode: '',
          ville: '',
          pays: 'France',
        },
        debutFormation: '12-12-2021',
        finFormation: '12-03-2021',
      },
      formations: [],
    }
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/formations`)
        .then(response => {
          this.formations = response.data
        })
        .catch(err => console.err(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/tuteurs`)
        .then(response => {
          this.tuteurs = response.data
        })
        .catch(err => console.err(err))
      this.$http.get(`${process.env.VUE_APP_API_URL}/partenaireSimple`)
        .then(part => {
          this.partenaires = part.data
        })
        .catch(error => console.error(error))
    },
  },
  setup() {
    return {
    }
  },
}
</script>
